import { cva, type VariantProps } from 'class-variance-authority'

export type ButtonVariantProps = VariantProps<typeof buttonVariants>

export type ButtonIntent = NonNullable<ButtonVariantProps['intent']>

export type ButtonColor = NonNullable<ButtonVariantProps['color']>

export type ButtonSize = NonNullable<ButtonVariantProps['size']>

export const buttonVariants = cva([
  'w-fit',
  'no-underline',
  'select-none',
  'font-body',
  'font-medium',
  'flex',
  'justify-center',
  'items-center',
  'ease-out',
  'duration-300',
  'transition-colors',
  'rounded-full',
], {
  variants: {
    intent: {
      primary: [],
      secondary: ['outline', 'outline-1', 'outline-[currentColor]'],
      tertiary: [],
    },
    size: {
      small: ['py-[10px]', 'px-m', 'gap-xs', 'text-sm'],
      medium: ['py-s', 'px-l', 'gap-s', 'text-base'],
      large: ['py-m', 'px-l', 'gap-s', 'text-xl'],
    },
    color: {
      blurple: ['text-blurple-500'],
      moss: ['text-moss-500'],
      carbon: [],
    },
    disabled: {
      true: ['bg-carbon-100', 'text-carbon-400', 'cursor-not-allowed', 'focus-visible:outline-offset-0', 'focus-visible:outline-none'],
      false: ['focus-visible:ring-3', 'focus-visible:ring-offset-4', 'focus-visible:ring-blurple-300', 'cursor-pointer'],
    },
  },
  compoundVariants: [
    {
      intent: 'secondary',
      disabled: false,
      class: 'hover:outline-2',
    },
    {
      intent: 'primary',
      color: 'blurple',
      disabled: false,
      class: [
        'bg-blurple-100',
        'text-blurple-500',
        'hover:bg-blurple-50',
        'active:bg-blurple-200',
        'dark:bg-blurple-300',
        // FIXME: Would make more sense if this was blurple-300, but the sketches say 100
        'dark:focus-visible:bg-blurple-100',
      ],
    },
    {
      intent: ['secondary', 'tertiary'],
      color: 'blurple',
      disabled: false,
      class: [
        'hover:bg-blurple-50',
        'active:bg-blurple-200',
        'dark:text-blurple-300',
        'dark:active:text-blurple-500',
        'dark:active:outline-blurple-300',
      ],
    },
    {
      intent: 'primary',
      color: 'moss',
      disabled: false,
      class: [
        'bg-moss-200',
        'hover:bg-moss-100',
        'active:bg-moss-300',
        'active:text-moss-200',
      ],
    },
    {
      intent: ['secondary', 'tertiary'],
      color: 'moss',
      disabled: false,
      class: [
        'hover:bg-moss-100',
        'active:bg-moss-300',
        'active:text-moss-200',
        'active:outline-moss-500',
        'dark:bg-transparent',
        'dark:text-moss-200',
        'dark:hover:bg-moss-100',
        'dark:hover:text-moss-500',
        'dark:hover:outline-moss-200',
        'dark:active:bg-moss-300',
        'dark:active:text-moss-200',
      ],
    },
    {
      intent: 'primary',
      color: 'carbon',
      disabled: false,
      class: [
        'text-carbon-50',
        'bg-carbon-500',
        'hover:bg-carbon-300',
        'active:bg-carbon-400',
        'dark:bg-carbon-50',
        'dark:text-carbon-500',
        'dark:hover:bg-carbon-200',
        'dark:active:bg-carbon-300',
        'dark:active:text-carbon-50',
      ],
    },
    {
      intent: ['secondary', 'tertiary'],
      color: 'carbon',
      disabled: false,
      class: [
        'text-carbon-500',
        'hover:text-carbon-50',
        'hover:bg-carbon-300',
        'active:text-carbon-50',
        'active:bg-carbon-400',
        'dark:text-carbon-50',
        'dark:hover:text-carbon-500',
        'dark:hover:bg-carbon-200',
        'dark:active:bg-carbon-300',
      ],
    },
    {
      intent: 'secondary',
      color: 'carbon',
      disabled: false,
      class: [
        'hover:outline-carbon-500',
        'dark:hover:outline-carbon-50',
      ],
    },
  ],
})
